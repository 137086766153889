import React from "react";

const options = [
    {
        name: 'UseAPI',
        scope: 'api',
        description: `Forces SQLAPI++ library to use ODBC or OLE DB API.`,
        valid: <><code>"OLEDB"</code>, {' '}<code>"ODBC"</code></>,
        defaultValue: <><code>"ODBC"</code>. SQLAPI++ uses ODBC as the default API</>
    },
    {
        name: 'SQLNCLI.LIBS',
        scope: 'api',
        description: `Specifies SQL Server Native client library list. SQLAPI++ tries to load libraries from this
                list one by one for loading SQL Server Native API.`,
        valid: `Any valid SQL Server ODBC/SQLNCLI library name list. Names separated by ';' on
                Windows or ':' on other OS.`,
        defaultValue:
            <ul>
                <li>Windows -
                    <code>"msodbcsql18.dll;msodbcsql17.dll;msodbcsql13.dll;msodbcsql11.dll;sqlncli11.dll;sqlncli10.dll;sqlncli.dll;sqlsrv32.dll"</code>
                </li>
                <li>Linux - <code>"libmsodbcsql-18.so:libmsodbcsql-17.so:libmsodbcsql.so"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked SQLServer client API functions when
                the library is compiled with {' '}<code className="text-reset">SA_STATIC_MSSQL</code>{' '} build option.
            </>
    },
    {
        name: 'SQL_COPT_SS_PRESERVE_CURSORS',
        scope: 'connection',
        description:
            <>
                When {' '}<code>"SQL_PC_ON"</code>{' '} value defined the option allows to use the result set after
                the commit or rollback command called for the current transaction.
            </>,
        valid: <code>"SQL_PC_ON"</code>,
        defaultValue: 'none'
    },
    {
        name: 'SQL_COPT_SS_ACCESS_TOKEN',
        scope: 'connection',
        description: `Specifies the access token used to authenticate to Azure Active Directory. See SQL Server documentation for more information.`,
        valid: <>The string like <code>"eyJ0eXAiOi..."</code> - in the format extracted from an OAuth JSON response</>,
        defaultValue: <>none</>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description: `Forces SQLAPI++ to use scrollable dynamic server side cursor.`,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'SetCursorName',
        scope: 'command',
        description: `Allows to define the cursor name SQLAPI++ uses for the server side cursor.`,
        valid: 'See SQLSetCursorName documentation',
        defaultValue: 'none'
    },
    {
        name: 'ExecDirect',
        scope: 'command',
        description: `Forces SQLAPI++ to use SQLExecDirect instead of SQLExecute API function.`,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'SQL_ATTR_CONCURRENCY',
        scope: 'command',
        description: `Sets the statement concurrency attribute. See SQL Server documentation for additional
                information.`,
        valid:
            <>
                <code>"SQL_CONCUR_READONLY"</code>,
                {' '}<code>"SQL_CONCUR_VALUES"</code>,
                {' '}<code>"SQL_CONCUR_ROWVER"</code>,
                {' '}<code>"SQL_CONCUR_LOCK"</code>
            </>
    },
    {
        scope: "command",
        name: 'SQL_ATTR_CURSOR_TYPE',
        description: `Sets the statement cursor type. See SQL Server documentation for an additional information.`,
        valid:
            <>
                <code>"SQL_CURSOR_FORWARD_ONLY"</code>,
                {' '}<code>"SQL_CURSOR_KEYSET_DRIVEN"</code>,
                {' '}<code>"SQL_CURSOR_DYNAMIC"</code>,
                {' '}<code>"SQL_CURSOR_STATIC"</code>
            </>
    },
    {
        scope: 'command',
        name: 'SQL_ATTR_CURSOR_SCROLLABLE',
        description: `Sets the statement cursor scrollable. See SQL Server documentation for an additional information.`,
        valid:
            <>
                <code>"SQL_NONSCROLLABLE"</code>,
                {' '}<code>"SQL_SCROLLABLE"</code>
            </>
    },
    {
        scope: 'command',
        name: 'SQL_ATTR_CURSOR_SENSITIVITY',
        description: `Sets the statement cursor sensitivity. See SQL Server documentation for an additional information.`,
        valid:
            <>
                <code>"SQL_UNSPECIFIED"</code>,
                {' '}<code>"SQL_INSENSITIVE"</code>,
                {' '}<code>"SQL_SENSITIVE"</code>
            </>
    },
    {
        scope: 'command',
        name: 'SQL_ATTR_QUERY_TIMEOUT',
        description: `Sets the integer value corresponding to the number of seconds to wait for an SQL statement
                      to execute before returning to the application.`,
        valid:
            <>
                String that represents an integer value in seconds
            </>,
        defaultValue:
            <>none</>
    },
    {
        scope: 'command',
        name: 'SQL_SOPT_SS_DEFER_PREPARE',
        description: `Determines whether the statement is prepared immediately or deferred until SQLExecute,
                      SQLDescribeCol or SQLDescribeParam is executed.`,
        valid:
            <>
                <code>"SQL_DP_OFF"</code> string that changes the default behavour
            </>,
        defaultValue:
            <>none (<code>SQL_DP_ON</code> is used by default)</>
    }
];

export {
    options
};
